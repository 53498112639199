import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { BarLoader } from 'react-spinners';
import { ImExit } from "react-icons/im";
import { toast } from 'react-toastify';
import {
  MdCheck,
  MdClose,
  MdDelete,
  MdDownload,
  // MdAdd,
  MdUpload,
  // MdClose 
} from 'react-icons/md';
import {
  FormContainer,
  Form,
  FormGroup,
  Label,
  Input,
  // Select,
  Button,
  ContainerButton,
  ButtonC,
  Select,
  // ButtonAdd,
  // Window,
  // FormAdd,
  // FormGroupAdd,
  // InputAdd,
  // ButtonSave
  Table,
  TableBody,
  TableContainer,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  TableRowHeader,
  NoReg,
  HeaderTitle,
  FormHeader,
  Close
} from './styles';
import api from '../../../../../services/api';
import { getToken } from '../../../../../utils/utils';


import { CadastroContainer } from '../styles';

class HoleriteUpload extends Component {
  state = {
    name: '',
    cpf: '',
    email: '',
    celular: '',
    password: '',
    data_nascimento: '',
    fk_empresa_id: '',
    fk_filial_id: '',
    fk_cargo_id: '',
    id_user_registered: '',
    id_file_registered: '',
    loading: false,
    empresas: [],
    filiais: [],
    cargos: [],
    file: '',
    files: [],
    mes: '',
    ano: ''
  };

  async componentDidMount() {
    this.handleGetData();
    this.handleGetFiles();
    const year = new Date().getFullYear();
    this.setState({ ano: year });
    const month = new Date().getMonth() + 1;
    this.setState({
      mes: month
    });

    const { user } = this.props;

    if (user) {
      this.setState({
        id_user_registered: user.id,
        name: user.name,
        cpf: user.cpf,
        email: user.email,
        celular: user.celular,
        data_nascimento: user.data_nascimento,
        fk_empresa_id: user.fk_empresa_id,
        fk_filial_id: user.fk_filial_id,
        fk_cargo_id: user.fk_cargo_id
      });
    }
    window.addEventListener('keydown', this.handleKeyDown);
  };

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  };

  handleKeyDown = (event) => {
    const { onClose } = this.props;
    if (event.key === 'Escape') {
      onClose();
    }
  };

  handleGetData = async () => {
    const token = getToken();
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
    await axios.get(`${api}?route=user-profile`, config).then((response) => {
      this.setState({
        id: response.data.id,
        name: response.data.name,
        cpf: response.data.cpf,
        email: response.data.email,
        data_nascimento: response.data.data_nascimento,
        celular: response.data.celular,
        fk_cargo_id: response.data.fk_cargo_id,
        fk_empresa_id: response.data.fk_empresa_id,
        fk_filial_id: response.data.fk_filial_id,
      })
    }).catch((err) => { });
    await axios.post(`${api}?route=filial-index`, {}, config).then((response) => {
      this.setState({
        filiais: response.data
      })
    }).catch((err) => { });
  };

  handleGetFiles = async () => {
    const token = getToken();
    const { user } = this.props;
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };

    await axios.post(`${api}?route=file-index-admin`, {
      fk_user_id: user.id
    }, config).then((response) => {
      this.setState({
        files: response.data ? response.data : []
      });
    }).catch((err) => {
      this.setState({
        files: []
      })
    });

  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { id_user_registered, file, mes, ano } = this.state;
    this.setState({ loading: true });

    const formData = new FormData();

    formData.append('fk_user_id', id_user_registered);
    formData.append('file', file);
    formData.append('mes', mes);
    formData.append('ano', ano);

    const token = getToken();
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
    await axios.post(`${api}?route=file-upload`, formData, config).then((response) => {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      this.setState({
        file: '',
        loading: false,
      });
      document.getElementById('fileInput').value = '';
      this.handleGetFiles();
    }).catch((err) => {
      if (err.response) {
        toast.error(err.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        this.setState({ loading: false });
      } else {
        toast.warn('Falha de conexão!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        this.setState({ loading: false });
      }
    });
  };

  handleDeleteFile = async (id) => {
    const token = getToken();
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
    const data = {
      file_id: id
    };

    await axios.post(`${api}?route=file-delete`, data, config).then((response) => {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      this.setState({
        file: '',
        loading: false
      });
      this.handleGetFiles();
    }).catch((err) => {
      if (err.response) {
        toast.error(err.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        this.setState({ loading: false });
      } else {
        toast.warn('Falha de conexão!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        this.setState({ loading: false });
      }
    });

  };

  handleMont = (numeroMes) => {
    let nomeMes;
    switch (numeroMes) {
      case 1:
        nomeMes = "Janeiro";
        break;
      case 2:
        nomeMes = "Fevereiro";
        break;
      case 3:
        nomeMes = "Março";
        break;
      case 4:
        nomeMes = "Abril";
        break;
      case 5:
        nomeMes = "Maio";
        break;
      case 6:
        nomeMes = "Junho";
        break;
      case 7:
        nomeMes = "Julho";
        break;
      case 8:
        nomeMes = "Agosto";
        break;
      case 9:
        nomeMes = "Setembro";
        break;
      case 10:
        nomeMes = "Outubro";
        break;
      case 11:
        nomeMes = "Novembro";
        break;
      case 12:
        nomeMes = "Dezembro";
        break;
      case 13:
        nomeMes = "Novembro 13º - 1ª Parcela";
        break;
      case 14:
        nomeMes = "Dezembro 13º - 2ª Parcela";
        break;
      default:
        nomeMes = "Mês inválido";
        break;
    }

    return nomeMes;
  };

  handleDownload = async (id, name) => {
    try {
      const token = getToken(); // Função para obter o token Bearer
      const data = {
        file_id: id
      };

      // Realiza a requisição POST para obter o arquivo
      const response = await axios.post(`${api}?route=file-download`, data, {
        responseType: 'blob', // Configura o tipo de resposta para blob
        headers: {
          Authorization: `Bearer ${token}`, // Inclui o token Bearer no cabeçalho
        },
      });

      // Cria um URL para o arquivo retornado
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Cria um link e faz o download do arquivo
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name); // Nome do arquivo para o download
      document.body.appendChild(link);
      link.click();

      // Remove o link do DOM após o download
      link.remove();

      // Revoga o URL para liberar memória
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Erro ao baixar o arquivo', error);
    }
  };

  render() {
    const { onClose, user } = this.props;
    const { loading, file, mes, ano, files } = this.state;
    return (
      <>
        <HeaderTitle>
          <h3>ID: {user.id} - {user.name}</h3>
        </HeaderTitle>
        <FormHeader>
          <Close type='button' onClick={() => onClose()}>
            <span>Esc</span>
            <ImExit size={20} />
          </Close>
        </FormHeader>
        <FormContainer>

          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              <Label>Data da Competência Mês/Ano</Label>
              <div className='container-input'>
                <Select onChange={(e) => this.setState({ mes: e.target.value })} value={this.state.mes} required>
                  <option value={1}>Janeiro</option>
                  <option value={2}>Fevereiro</option>
                  <option value={3}>Março</option>
                  <option value={4}>Abril</option>
                  <option value={5}>Maio</option>
                  <option value={6}>Junho</option>
                  <option value={7}>Julho</option>
                  <option value={8}>Agosto</option>
                  <option value={9}>Setembro</option>
                  <option value={10}>Outubro</option>
                  <option value={11}>Novembro</option>
                  <option value={12}>Dezembro</option>
                  <option value={13}>Novembro 13º - 1ª Parcela</option>
                  <option value={14}>Dezembro 13º - 2ª Parcela</option>
                </Select>

                <Input
                  type="number"
                  name="ano"
                  value={this.state.ano}
                  onChange={(e) => this.setState({ ano: e.target.value })}
                  required
                />
              </div>
            </FormGroup>

            <FormGroup>
              <Label>Anexar arquivo</Label>
              <Input
                id="fileInput"
                type="file"
                name="file"
                onChange={(event) => this.setState({ file: event.target.files[0] })}
                required
                selected={file}
                style={{
                  width: '98.5%'
                }}
              />
            </FormGroup>

            <ContainerButton>
              <ButtonC type="button" onClick={() => onClose()}>Cancelar</ButtonC>
              <Button type="submit" disabled={!file || !mes || !ano}>
                {!loading && <MdUpload size={20} />}
                {loading ? <BarLoader color='white' size={30} /> : `Publicar`}
              </Button>
            </ContainerButton>
          </Form>
        </FormContainer>

        {files.length ? (
          <TableContainer>
            <CadastroContainer>
              <Input placeholder='Buscar...' />
            </CadastroContainer>
            <Table>
              <TableHead>
                <TableRowHeader>
                  <TableHeader >ID</TableHeader>
                  <TableHeader>Nome</TableHeader>
                  <TableHeader>Mês</TableHeader>
                  <TableHeader>Ano</TableHeader>
                  <TableHeader>Assinado</TableHeader>
                  <TableHeader>Baixado</TableHeader>
                  <TableHeader>Deletar</TableHeader>
                  <TableHeader>Download</TableHeader>
                </TableRowHeader>
              </TableHead>
              <TableBody>
                {files.map((file) => (
                  <TableRow key={file.id}>
                    <TableData>{file.id}</TableData>
                    <TableData>{file.name}</TableData>
                    <TableData style={{
                      textTransform: 'capitalize'
                    }}>{this.handleMont(Number(file.mes))}</TableData>
                    <TableData>{file.ano}</TableData>
                    <TableData>
                      <div className='status'>
                        {file.assinado ? <MdCheck size={25} color='green' /> : <MdClose size={25} color='red' />}                                          </div>
                    </TableData>
                    <TableData>
                      <div className='status'>
                        {file.baixado ? <MdCheck size={25} color='green' /> : <MdClose size={25} color='red' />}                                          </div>
                    </TableData>
                    <TableData>
                      <button className='btn-delete' type='button' onClick={() => this.handleDeleteFile(file.id)}><MdDelete size={25} /></button>
                    </TableData>
                    <TableData>
                      <button className='btn-download' type='button' onClick={() => this.handleDownload(file.id, file.name)}><MdDownload size={25} /></button>
                    </TableData>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NoReg>
            <h3>Sem arquivos no momento!</h3>
          </NoReg>
        )}
      </>
    );
  }
}

HoleriteUpload.propTypes = {
  onClose: PropTypes.func.isRequired,
  user: PropTypes.func.isRequired
}

export default HoleriteUpload;
