import React, { Component } from 'react';
import axios from 'axios';
import { BarLoader } from 'react-spinners';
// import { MdUpload } from 'react-icons/md';
import HoleriteUpload from './HoleriteUpload';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableData,
    // Cadastro, 
    CadastroContainer,
    TableRowHeader,
    // Input,
    NoReg,
    Loading
} from './styles';
import api from '../../../../services/api';
import { getToken } from '../../../../utils/utils';


class Holerites extends Component {
    state = {
        register: false,
        users: [],
        empresas: [],
        filial: [],
        cargo: [],
        user: '',
        loading: false,
        filters: [],
        name_filter: '',
        cargo_filter: '',
        empresa_filter: '',
        filial_filter: '',
        cpf_filter: '',
        ativo_filter: ''
    };

    async componentDidMount() {
        this.handleGetData();
    };
    handleGetData = async () => {
        this.setState({ loading: true });
        const token = getToken();
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        await axios.post(`${api}?route=user-index`, {}, config).then((response) => {
            const sortedUsers = response.data.sort((a, b) => {
                return a.name.localeCompare(b.name); // Ordena alfabeticamente
            });

            this.setState({
                users: sortedUsers
            });

            this.handleFilter('ativo', 1);
        }).catch((err) => {

        });
        await axios.post(`${api}?route=filial-index`, {}, config).then((response) => {
            this.setState({
                filial: response.data
            })
        }).catch((err) => {

        });
        await axios.post(`${api}?route=empresa-index`, {}, config).then((response) => {
            this.setState({
                empresas: response.data
            })
        }).catch((err) => {

        });

        await axios.get(`${api}?route=cargo-index`, config).then((response) => {
            this.setState({
                cargo: response.data
            })
            setTimeout(() => {
                this.handleCargosForFilter();
            }, 100);
        }).catch((err) => {

        });
        this.setState({ loading: false });
    };
    cargo = (id) => {
        const { cargo } = this.state;
        const c = cargo.find((cg) => cg.id === id);
        return c ? c.name : '----'
    };
    empresa = (id) => {
        const { empresas } = this.state;
        const e = empresas.find((cg) => cg.id === id);
        return e ? e.name : '----'
    };
    filial = (id) => {
        const { filial } = this.state;
        const f = filial.find((cg) => cg.id === id);
        return f ? f.name : '----'
    };


    handleFilter = (col, value) => {

        switch (col) {
            case 'name':
                this.setState({ name_filter: value });
                break;
            case 'cpf':
                this.setState({ cpf_filter: value });
                break;
            case 'cargo':
                this.setState({ cargo_filter: value });
                break;
            case 'empresa':
                this.setState({ empresa_filter: value });
                break;
            case 'filial':
                this.setState({ filial_filter: value });
                break;
            case 'ativo':
                this.setState({ ativo_filter: value });
                break;
            default:
                break;
        }

        setTimeout(() => {
            this.handleFilterData();
        }, 100);
    };

    handleFilterData = () => {
        const { users, name_filter, cargo_filter, empresa_filter, filial_filter, cpf_filter, ativo_filter } = this.state;

        // Criamos um array de filtros dinâmico
        const filters = [
            { key: 'name', value: name_filter, match: (user, value) => user.name.toLowerCase().includes(value.toLowerCase()) },
            { key: 'cargo', value: cargo_filter, match: (user, value) => Number(user.fk_cargo_id) === Number(value) },
            { key: 'empresa', value: empresa_filter, match: (user, value) => Number(user.fk_empresa_id) === Number(value) },
            { key: 'filial', value: filial_filter, match: (user, value) => Number(user.fk_filial_id) === Number(value) },
            { key: 'cpf', value: cpf_filter, match: (user, value) => user.cpf.includes(value) },
            { key: 'active', value: ativo_filter, match: (user, value) => Number(user.active) === (Number(value) !== '' ? Number(value) : 1 )},
        ];

        // Filtra os usuários com base nos filtros que foram preenchidos
        const filteredUsers = users.filter(user => {
            return filters.every(filter => {
                // Só aplicamos o filtro se o valor estiver preenchido
                return !filter.value || filter.match(user, filter.value);
            });
        });

        // Atualiza o estado com os usuários filtrados
        this.setState({ filters: filteredUsers });
    };

    handleCargosForFilter = () => {
        const { users, cargo } = this.state;
        const cargos = [];
        users.forEach((user) => {
            const is_reg = cargos.find((c) => Number(c.id) === Number(user.fk_cargo_id));
            if (!is_reg) {
                const cargo_add = cargo.find((cd) => Number(cd.id) === Number(user.fk_cargo_id));
                cargos.push(cargo_add);
            }
        });
        console.log(cargos);
        this.setState({
            cargo: cargos
        })
    };


    render() {
        const { register, users, loading, cargo, empresas, filial, filters } = this.state;
        return (
            <>
                {!loading && register && (
                    <HoleriteUpload onClose={() => { this.setState({ register: false, user: '' }); this.handleGetData(); }} onReload={() => this.handleGetData()} user={this.state.user} />
                )}

                {!loading && !register && (
                    <TableContainer>
                        <CadastroContainer>
                            {/* <Input placeholder='Filtrar pelo nome 🔍' onChange={(e) => this.handleFilter('name', e.target.value)} /> */}
                        </CadastroContainer>
                        <Table>
                            <TableHead>
                                <TableRowHeader>
                                    <TableHeader>ID</TableHeader>
                                    <TableHeader>Nome <input style={{ width: '150px' }} type='text' placeholder='...' onChange={(e) => this.handleFilter('name', e.target.value)} /></TableHeader>
                                    <TableHeader>Função
                                        <select onChange={(e) => this.handleFilter('cargo', e.target.value)}>
                                            <option value="">...</option>
                                            {cargo.map((c) => (
                                                <option value={c.id}>{c.name}</option>
                                            ))}
                                        </select>
                                    </TableHeader>
                                    <TableHeader>Empresa
                                        <select onChange={(e) => this.handleFilter('empresa', e.target.value)}>
                                            <option value="">...</option>
                                            {empresas.map((e) => (
                                                <option value={e.id}>{e.name}</option>
                                            ))}
                                        </select>
                                    </TableHeader>
                                    <TableHeader>Filial
                                        <select onChange={(e) => this.handleFilter('filial', e.target.value)}>
                                            <option value="">...</option>
                                            {filial.map((f) => (
                                                <option value={f.id}>{f.name}</option>
                                            ))}
                                        </select>
                                    </TableHeader>
                                    <TableHeader>CPF
                                        <input type='text' placeholder='Cpf...' onChange={(e) => this.handleFilter('cpf', e.target.value)} />
                                    </TableHeader>
                                    {/* <TableHeader>Publicar</TableHeader> */}

                                    <TableHeader>Ativo
                                    <select onChange={(e) => this.handleFilter('ativo', e.target.value)}>
                                            <option value="">...</option>
                                            <option value={1}>SIM</option>
                                            <option value={0}>NÃO</option>
                                        </select>
                                    </TableHeader>
                                </TableRowHeader>
                            </TableHead>
                            <TableBody>
                                {!filters.length ? users.map((user) => (
                                    <TableRow key={user.id} onClick={() => this.setState({ register: true, user: user })}>
                                        <TableData>{user.id}</TableData>
                                        <TableData>{user.name}</TableData>
                                        <TableData>{this.cargo(user.fk_cargo_id)}</TableData>
                                        <TableData>{this.empresa(user.fk_empresa_id)}</TableData>
                                        <TableData>{this.filial(user.fk_filial_id)}</TableData>
                                        <TableData>{user.cpf}</TableData>
                                        <TableData>{user.active ? 'SIM' : 'NÃO'}</TableData>
                                        {/* <TableData><Cadastro onClick={() => this.setState({ register: true, user: user})}><MdUpload size={20} />Publicar </Cadastro></TableData> */}
                                    </TableRow>
                                )) : ''}

                                {filters.length ? filters.map((user) => (
                                    <TableRow key={user.id} onClick={() => this.setState({ register: true, user: user })}>
                                        <TableData>{user.id}</TableData>
                                        <TableData>{user.name}</TableData>
                                        <TableData>{this.cargo(user.fk_cargo_id)}</TableData>
                                        <TableData>{this.empresa(user.fk_empresa_id)}</TableData>
                                        <TableData>{this.filial(user.fk_filial_id)}</TableData>
                                        <TableData>{user.cpf}</TableData>
                                        <TableData>{user.active ? 'SIM' : 'NÃO'}</TableData>
                                        {/* <TableData><Cadastro onClick={() => this.setState({ register: true, user: user})}><MdUpload size={20} />Publicar </Cadastro></TableData> */}
                                    </TableRow>
                                )) : ''}

                            </TableBody>
                        </Table>
                        {!users.length && (
                            <NoReg>
                                <h3>Usuários Indisponíveis</h3>
                            </NoReg>
                        )}
                    </TableContainer>
                )}

                {loading && (
                    <Loading>
                        <BarLoader />
                    </Loading>
                )}
            </>
        );
    }
}

export default Holerites;
