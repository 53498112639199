import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { BarLoader } from 'react-spinners';
import { SignInContainer, SignInForm, InputGroup, Label, Input, SubmitButton, Footer } from './styles';
import api from '../../services/api';
import logoImg from './logo.png';
import background from './background.jpg';

class SignIn extends Component {

  state = {
    email: '',
    password: '',
    dashboard: false,
    loading: false
  };

  componentDidMount(){
    document.getElementById('emailInput').focus();
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : false;
    if(user){
      this.setState({ dashboard: true });
    }
  }


  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    // Lógica de envio do formulário aqui
    const { email, password } = this.state;
    const data = {
      email,
      password
    };

    await axios.post(`${api}?route=session`, data).then((response) => {
      if (response.data.token) {
        this.setState({
          dashboard: true,
          loading: false
        })
        localStorage.setItem('user', JSON.stringify(response.data));
      }else {
        toast.warn('Falha de conexão!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        this.setState({loading: false})
      }
    }).catch((err) => {
      if (err.response) {
        toast.error(err.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        this.setState({ loading: false });
      } else {
        toast.warn('Falha de conexão!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        this.setState({ loading: false });
      }
    });
  };

  render() {
    const { dashboard, loading } = this.state;
    return (
      <SignInContainer image={background}>
        {dashboard && <Navigate to="/dashboard" />}
        <SignInForm onSubmit={this.handleSubmit}>
          <center>
            <img src={logoImg} width={200} alt='logo' />
          </center>
          <InputGroup>
            <Label>Email:</Label>
            <Input
              id="emailInput"
              type="email"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              required
            />
          </InputGroup>
          <InputGroup>
            <Label>Password:</Label>
            <Input
              id="passwordInput"
              type="password"
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
              required
            />
          </InputGroup>
          <SubmitButton type="submit">
            {loading ? <BarLoader color='white' size={30} /> : 'Entrar'}
          </SubmitButton>
          <Footer>
            <span className='version'>v 1.0.3</span>  
          </Footer>
        </SignInForm>
      </SignInContainer>
    );
  }
}

export default SignIn;
